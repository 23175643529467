<template>
   <!-- Start Upperbar -->
   <div id="upper-bar" class="upper-bar">
      <div class="container">
         <div class="row">
            <div class="col-12">
               <div class="inner-bar">
                  <div class="row">
                     <div class="col-lg-8 col-12 text-center text-lg-left">
                        <ul class="contact-bar list-unstyled">
                           <li>
                              <a href="mailto:support@example.com">
                              <i class="fa fa-envelope"></i>
                              support@example.com
                              </a>
                           </li>
                           <li>
                              <a href="tel:+1 212 118-2110">
                              <i class="fas fa-phone-alt"></i>
                              +1 212 118-2110
                              </a>
                           </li>
                           <li>
                              <i class="fa fa-map-marker"></i>
                              10 Street Name, NewYork, USA
                           </li>
                        </ul>
                     </div>
                     <div class="col-lg-4 col-12 text-center text-lg-right">
                        <ul class="social-media-bar list-unstyled">
                           <li>
                              <a href="https://themeforest.net/" target="_blank">
                              <i class="fab fa-facebook-f"></i>
                              </a>
                           </li>
                           <li>
                              <a href="https://themeforest.net/" target="_blank">
                              <i class="fab fa-twitter"></i>
                              </a>
                           </li>
                           <li>
                              <a href="https://themeforest.net/" target="_blank">
                              <i class="fab fa-instagram"></i>
                              </a>
                           </li>
                           <li>
                              <a href="https://themeforest.net/" target="_blank">
                              <i class="fab fa-dribbble"></i>
                              </a>
                           </li>
                           <li>
                              <a href="https://themeforest.net/" target="_blank">
                              <i class="fab fa-linkedin-in"></i>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- End Upperbar -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>